import React, { useState } from "react";
import "../styling/Community.css";
import Rizanesti from "../assets/rizanest1.avif";
import Valea from "../assets/valeascrezii.webp";
import ModalRizanesti from "../components/ModalRizanesti";
import ModalScrezii from "../components/ModalScrezii";

const Community = () => {
  const [isRizanestiOpen, setIsRizanestiOpen] = useState(false);
  const [isScreziiOpen, setIsScreziiOpen] = useState(false);

  const openRizanestiModal = () => setIsRizanestiOpen(true);
  const closeRizanestiModal = () => setIsRizanestiOpen(false);

  const openScreziiModal = () => setIsScreziiOpen(true);
  const closeScreziiModal = () => setIsScreziiOpen(false);

  return (
    <div id="community" className="community-container">
      <h3 className="title-community">Comunitățile noastre</h3>
      <div className="cards-container-community">
        <div className="card-community" onClick={openRizanestiModal}>
          <img
            className="image-community"
            src={Valea}
            alt="Centrul Social Rizanesti"
          />
          <h3 className="title-card-community">CTF Rizănești</h3>
        </div>
        <div className="card-community" onClick={openScreziiModal}>
          <img
            className="image-community"
            src={Rizanesti}
            alt="Tabăra din Valea Screzii"
          />
          <h3 className="title-card-community">Tabăra din Valea Screzii</h3>
        </div>
      </div>

      <ModalRizanesti isOpen={isRizanestiOpen} onClose={closeRizanestiModal} />
      <ModalScrezii isOpen={isScreziiOpen} onClose={closeScreziiModal} />
    </div>
  );
};

export default Community;
