import React from "react";
import "../styling/How.css";
import Casa from "../assets/casa.webp";
import Hrana from "../assets/mancare.webp";
import Sprijin from "../assets/educatie.webp";
import Ocupatie from "../assets/sprijin.webp";
import Recreere from "../assets/recreere.webp";
import Suport from "../assets/suport.webp";

const How = () => {
  return (
    <div id="how" className="how-container">
      <h1 className="how-title">Cum ajutăm?</h1>
      <div className="container-cards-how">
        <div className="card-how">
          <img
            className="image-card-how"
            src={Casa}
            alt="cum ajutam? Adapost"
          />
          <h4 className="title-card-how">Casă</h4>
        </div>
        <div className="card-how">
          <img className="image-card-how" src={Hrana} alt="cum ajutam? Hrana" />
          <h4 className="title-card-how">Hrană</h4>
        </div>
        <div className="card-how">
          <img
            className="image-card-how"
            src={Sprijin}
            alt="cum ajutam? Sprijin Educational"
          />
          <h4 className="title-card-how">Sprijin educațional</h4>
        </div>
        <div className="card-how">
          <img
            className="image-card-how"
            src={Ocupatie}
            alt="cum ajutam? Sprijin Ocupational"
          />
          <h4 className="title-card-how">Sprijin ocupațional</h4>
        </div>
        <div className="card-how">
          <img
            className="image-card-how"
            src={Recreere}
            alt="cum ajutam? Recreere"
          />
          <h4 className="title-card-how">Recreere</h4>
        </div>
        <div className="card-how">
          <img
            className="image-card-how"
            src={Suport}
            alt="cum ajutam? Recreere"
          />
          <h4 className="title-card-how">Suport psihologic</h4>
        </div>
      </div>
    </div>
  );
};

export default How;
