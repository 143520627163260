// src/components/ModalAbout.jsx
import React from "react";
import About1 from "../assets/about1.webp";
import About2 from "../assets/about2.webp";
import About3 from "../assets/about3.webp";

import "../styling/ModalAbout.css";

const ModalAbout = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    if (e.target.className === "modal-overlay-about") {
      onClose();
    }
  };

  return (
    <div className="modal-overlay-about" onClick={handleClose}>
      <div className="modal-content-about">
        <h2 className="title-modal-about">
          Mai multe detalii despre asociația Pro Vita
        </h2>
        <section className="section-modal-about">
          <h3 className="title-section-modal-about">Soluția noastră</h3>
          <p className="para-modal-about">
            Asociația noastră vine în sprijinul individului și al statului și
            oferă neîncetat adăpost, hrană, suport educațional, psihologic și
            ocupațional, facilitând accesul la servicii medicale, activități
            culturale și recreative pentru cei care au nevoie.
          </p>
          <p className="para-modal-about">
            Primim ajutor sub formă de resurse materiale, financiare și timp din
            partea persoanelor fizice, juridice și a autorităților publice,
            pentru a ne asigura că beneficiarii noștri trăiesc într-un mediu
            sănătos și au oportunități de dezvoltare.
          </p>

          <img
            className="image-about-modal"
            src={About1}
            alt="solutia noastra asociatia pro vita"
          />
        </section>

        <section className="section2 section-modal-about">
          <h3 className="title-section-modal-about">Beneficiarii noștri</h3>
          <ul className="ul-about-modal">
            <li>Copii abandonați</li>
            <li>Familii monoparentale</li>
            <li>Persoane aflate în dificultate</li>
            <li>Tineri care părăsesc sistemul de protecție specială</li>
            <li>Vârstnici</li>
            <li>Persoane cu dizabilități</li>
            <li>Alte persoane defavorizate</li>
          </ul>
          <img
            className="image-about-modal"
            src={About2}
            alt="solutia noastra asociatia pro vita"
          />
        </section>

        <section className="section-modal-about">
          <h3 className="title-section-modal-about">Principiile noastre</h3>
          <h4 className="subtitle-modal-about">
            Egalitatea de șanse și tratament
          </h4>
          <p className="para-modal-about">
            Egalitatea este o valoare fundamentală în asociația noastră. Oferim
            același tratament, indiferent de sex, rasă, culoare, origine etnică
            sau socială, religie, opinii politice sau de altă natură,
            dizabilități, vârstă sau orientare sexuală.
          </p>

          <h4 className="subtitle-modal-about">Dezvoltarea durabilă</h4>
          <p className="para-modal-about">
            Asigurăm echilibrul între aspectele sociale, economice și ecologice
            în activitățile noastre, satisfăcând nevoile prezentului fără a
            compromite viitorul.
          </p>

          <h4 className="subtitle-modal-about">Inovarea socială</h4>
          <p className="para-modal-about">
            Dezvoltăm idei, servicii și modele noi pentru a aborda mai bine
            provocările sociale, colaborând cu actori publici și privați pentru
            îmbunătățirea serviciilor sociale.
          </p>
          <img
            className="image-about-modal"
            src={About3}
            alt="solutia noastra asociatia pro vita"
          />
        </section>
      </div>
    </div>
  );
};

export default ModalAbout;
