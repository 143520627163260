import React from "react";
import "../styling/DonateModal.css";
import Copy from "../assets/copy.svg";

const DonateModal = ({ isOpen, onClose, copyToClipboard }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Donează</h2>
        <div className="iban-container">
          <p className="currency">IBAN RON</p>
          <span className="iban-text" id="iban3">
            RO76BTRLRONCRT0P25992903
          </span>
          <button
            className="copy-button"
            onClick={() => copyToClipboard("iban3")}>
            <img src={Copy} alt="Copy" />
          </button>
        </div>
        <div className="iban-container">
          <p className="currency">IBAN EURO</p>
          <span className="iban-text" id="iban2">
            RO18BTRLEURCRT0P25992901
          </span>
          <button
            className="copy-button"
            onClick={() => copyToClipboard("iban2")}>
            <img src={Copy} alt="Copy" />
          </button>
        </div>
        <div className="iban-container">
          <p className="currency">IBAN USD</p>
          <span className="iban-text" id="iban1">
            RO58BTRLUSDCRT0P25992901
          </span>
          <button
            className="copy-button"
            onClick={() => copyToClipboard("iban1")}>
            <img src={Copy} alt="Copy" />
          </button>
        </div>
        <button className="close-modal" onClick={onClose}>
          Închide
        </button>
      </div>
    </div>
  );
};

export default DonateModal;
