import React from "react";
import "../styling/Home.css";
import Header from "../assets/rizanesti.webp";
import { Link as ScrollLink } from "react-scroll";

const Home = () => {
  return (
    <div id="home" className="home-container">
      <div className="top-home">
        <div className="left-side-home">
          <h1 className="home-title">
            Oferim copiilor șansa la un viitor mai luminos.
          </h1>
          <h2 className="subtitle-home">
            Pentru o viață plină de iubire și oportunități.
          </h2>
          <ScrollLink to="help" smooth={true} duration={500} offset={-50}>
            <button className="home-donate">Descoperă cum poți dona</button>
          </ScrollLink>
        </div>
        <div className="right-side-home">
          <img
            className="header-image"
            src={Header}
            alt="impreuna pentru un viitor mai bun, doneaza si tu pe voluntar-provita.ro"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
