// src/components/ModalRizanesti.jsx
import React from "react";
import "../styling/ModalCommunity.css";

const ModalRizanesti = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    if (e.target.className === "modal-overlay") {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content">
        <h2 className="title-modal-community">
          Casele de tip familial „Rizănești” și „Sf.Apostol și Evanghelist
          Matei”
        </h2>

        <p>
          Suntem 40 de suflete, copii defavorizați, plasați în grija Asociației
          Pro Vita. Trăim într-o casă de tip familial de la marginea frumosului
          oraș Vălenii de Munte. Casa noastră este lângă pădure, unde aerul este
          curat și liniștea domină. Pe aici trec deseori căprioare, vulpi,
          veverițe, turme de oi și cai. Avem și câini și pisici, iar apusurile
          par desprinse din basmele lui Petre Ispirescu.
        </p>

        <h3 className="subtitle-modal-community">Viața la Rizănești și Sf. Matei</h3>
        <p>
          Nu am ajuns aici pentru că ne-am dorit, dar alegem să rămânem pentru
          că ne place și ne este bine. Unii dintre noi nu avem familii, alții nu
          le putem cunoaște, iar alții au familii cu care țin legătura, dar din
          diverse motive nu pot crește cu ei.
        </p>
        <p>
          Aici avem tot ce ne trebuie: camere frumos amenajate, hrană, haine, o
          bibliotecă, spațiu pentru învățat, o bucătărie, o sală mare de mese,
          un teren de baschet, jucării, biciclete, telefoane, laptop-uri și
          acces la Wi-Fi.
        </p>

        <h3 className="subtitle-modal-community">Dorințele noastre</h3>
        <p>
          Suntem copii ca toți ceilalți: ne dorim să mergem la cinema și mall,
          în excursii și tabere cu școala, telefoane de ultimă generație, aparat
          dentar (pentru că a devenit "cool"), să intrăm la facultate și să
          devenim independenți.
        </p>

        <h3 className="subtitle-modal-community">Copiii ucraineni</h3>
        <p>
          De când a început războiul în Ucraina, am primit lângă noi 26 de copii
          ucraineni dezrădăcinați peste noapte. Împărțim totul cu ei și, deși nu
          ne-a fost ușor, am învățat să ne înțelegem și să ne acceptăm.
        </p>

        <h3 className="subtitle-modal-community">Ajutorul pe care îl primim</h3>
        <p>
          Primim ajutor de la oameni buni și companii generoase care acoperă
          nevoile noastre de bază. Mulți dintre cei care ne vizitează devin
          prietenii noștri pe viață și revin pentru a se încărca cu voia noastră
          bună. Din păcate cei care ne vizitează sunt tot mai puțini iar nevoile
          noastre cresc o dată cu vârsta. Permanent avem nevoie de alimente,
          produse de igienă, acopeririea cheltuielilor cu energia electrică,
          apă, căldură etc.
        </p>

        <h3 className="subtitle-modal-community">Oameni care ne ajută</h3>
        <p>
          Suntem sprijiniți de o armată de oameni dedicați, angajați și
          voluntari. Melanie și Mihail se asigură că nu ne lipsește nimic, Rux
          și Carmen ne ajută cu documentele și mersul la școală, Luminița,
          asistenta noastră medicală, are grijă de sănătatea noastră, iar Buc,
          bucătăreasa, gătește de te lingi pe degete.
        </p>
        <p>
          Anca și Marius ne ajută cu temele și ne pregătesc pentru examene.
          Îngrijitoarele noastre sunt ca niște mame: Gabi, Angi, Cristina,
          Georgeta, Ramona, Mari, Carmen, Alina, Mihaela. Nea’ Traian se ocupă
          de serele noastre, și mulți alții care ne ajută zi de zi.
        </p>

        <h3 className="subtitle-modal-community">Contribuția noastră</h3>
        <p>
          Contribuim și noi la bunul mers al lucrurilor în casă: facem
          curățenie, ajutăm echipa, facem mărțișoare și ornamente de Paște și
          Crăciun, colindăm și oferim oamenilor buni care ne ajută zâmbete,
          atenția noastră și rugăciuni pentru sănătatea și puterea lor.
        </p>
      </div>
    </div>
  );
};

export default ModalRizanesti;
