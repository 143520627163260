import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styling/Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    nume: "",
    email: "",
    telefon: "",
    mesaj: "",
  });

  const [status, setStatus] = useState(""); // 'loading', 'success', 'error'

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    // Trimiterea formularului prin EmailJS
    emailjs
      .send(
        "EmailContactForm",
        "template_voluntar",
        formData,
        "szRGJa9ywWws5H6nq"
      )
      .then(
        () => {
          setStatus("success");
          setFormData({
            nume: "",
            email: "",
            telefon: "",
            mesaj: "",
          });
          setTimeout(() => setStatus(""), 3000); // Reset status after 3 seconds
        },
        () => {
          setStatus("error");
          setTimeout(() => setStatus(""), 3000); // Reset status after 3 seconds
        }
      );
  };

  return (
    <div id="contact" className="contact-container">
      <h1 className="title-contact">Vrei să luăm legătura?</h1>
      <div className="low-contact">
        <div className="form-contact">
          {status === "loading" && <div className="spinner"></div>}
          {status === "success" && (
            <div className="success-checkmark">
              <div className="check"></div>
            </div>
          )}
          {status === "error" && <div className="error-cross"></div>}
          {status === "" && (
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="input-holder">
                <label className="label" htmlFor="nume">
                  Nume:
                </label>
                <input
                  type="text"
                  id="nume"
                  className="input"
                  name="nume"
                  value={formData.nume}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-holder">
                <label className="label" htmlFor="email">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  className="input"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-holder">
                <label className="label" htmlFor="telefon">
                  Telefon:
                </label>
                <input
                  type="tel"
                  id="telefon"
                  className="input"
                  name="telefon"
                  value={formData.telefon}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-holder">
                <label className="label" htmlFor="mesaj">
                  Mesaj:
                </label>
                <textarea
                  id="mesaj"
                  name="mesaj"
                  className="input"
                  value={formData.mesaj}
                  onChange={handleChange}
                  minLength="10"
                  required></textarea>
              </div>
              <button className="submit" type="submit">
                Trimite
              </button>
            </form>
          )}
        </div>
        <div className="map-location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2811.2858075250288!2d26.054101799999998!3d45.2015517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b3a537012236ed%3A0x81567047014a02ec!2zU3RyYWRhIFJpesSDbmXImXRpLCBSb21hbmlh!5e0!3m2!1sen!2sit!4v1724411710282!5m2!1sen!2sit"
            height="600"
            className="map"
            width="100%"
            style={{ border: 0, margin: 0 }}
            allowFullScreen=""
            title="maps"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
