import React, { useState } from "react";
import "../styling/Navbar.css";
import Logo from "../assets/logo.svg";
import DonateModal from "./DonateModal";

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const copyToClipboard = (elementId) => {
    const textToCopy = document.getElementById(elementId).textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("IBAN Copiat: " + textToCopy);
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
      setIsSidebarOpen(false); // Închide sidebar-ul după scroll
    }
  };

  const scrollToHome = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsSidebarOpen(false); // Închide sidebar-ul după scroll
  };

  return (
    <div className="navbar">
      <img
        className="logo-navbar"
        src={Logo}
        alt="Logo"
        onClick={scrollToHome}
        style={{ cursor: "pointer" }}
      />
      <div className="navbar-doneaza">
        <button className="doneaza-button" onClick={toggleModal}>
          DONEAZĂ
        </button>
      </div>
      <div className="navbar-hamburger" onClick={toggleSidebar}>
        &#9776;
      </div>

      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="close-button" onClick={toggleSidebar}>
          &times;
        </div>
        <ul className="sidebar-menu">
          <li onClick={() => scrollToSection("about")}>Despre</li>
          <li onClick={() => scrollToSection("how")}>Cum ajutăm?</li>
          <li onClick={() => scrollToSection("community")}>
            Comunitățile noastre
          </li>
          <li onClick={() => scrollToSection("help")}>Ajută și tu!</li>
          <li onClick={() => scrollToSection("contact")}>Contact</li>
        </ul>

        {/* Secțiunea de jos cu cele 3 butoane */}
        <div className="navbar-links">
          <a
            href="https://d1a2bc0b-0643-423a-b2fd-25f1fdb536b4.filesusr.com/ugd/55a85d_d345201159d34054977efdc270509e4d.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-link-button">
            Formular 230 ANAF
          </a>
          <a
            href="https://d1a2bc0b-0643-423a-b2fd-25f1fdb536b4.filesusr.com/ugd/55a85d_33d5569b36e5464dbbc01d99431170f9.docx?dn=CONTRACT%20DE%20SPONSORIZARE.docx"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-link-button">
            Contract de sponsorizare
          </a>
          <a
            href="https://d1a2bc0b-0643-423a-b2fd-25f1fdb536b4.filesusr.com/ugd/55a85d_f92e0686862d4ab89f993cf4442cb8e3.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-link-button">
            Acreditare servicii sociale
          </a>
        </div>
      </div>
      <DonateModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        copyToClipboard={copyToClipboard}
      />
    </div>
  );
};

export default Navbar;
