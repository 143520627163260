import React, { useState } from "react";
import "../styling/Footer.css";
import LogoFooter from "../assets/logo.svg";
import Mail from "../assets/mail.svg";
import Phone from "../assets/phone.svg";
import UPVM from "../assets/upvm.png";
import Facebook from "../assets/facebook.png";
import Instagram from "../assets/instagram.png";

import { Link as ScrollLink } from "react-scroll";
import Termeni from "./Termeni";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="footer-container">
      <div className="top-footer">
        <ScrollLink
          className="image-holder"
          to="home"
          smooth={true}
          duration={500}
          offset={-50}>
          <img
            className="image-footer"
            src={LogoFooter}
            alt="asociatia provita"
          />
        </ScrollLink>
        <div className="contact-line-footer">
          <img
            className="image-contact-line"
            src={Mail}
            alt="contact@voluntar-provita.ro"
          />
          <a
            className="anchor-footer"
            href="mailto:contact@voluntar-provita.ro">
            contact@voluntar-provita.ro
          </a>
        </div>
        <div className="telephone contact-line-footer">
          <div className="phone-line">
            <img
              className="image-contact-line"
              src={Phone}
              alt="contact@voluntar-provita.ro"
            />
            <a
              className="anchor-footer"
              href="https://wa.me/40745068000"
              target="_blank"
              rel="noopener noreferrer">
              +40 745 068 000
            </a>
          </div>
          <div className="phone-line">
            <img
              className="image-contact-line"
              src={Phone}
              alt="contact@voluntar-provita.ro"
            />
            <a
              className="anchor-footer"
              href="https://wa.me/40741989024"
              target="_blank"
              rel="noopener noreferrer">
              +40 741 989 024
            </a>
          </div>
        </div>

        <div className="contact-line-footer social-footer">
          <a
            className="anchor-footer-social"
            target="blank"
            href="https://www.facebook.com/centrulrizanesti/">
            <img
              className="image-contact-line-social"
              src={Facebook}
              alt="contact@voluntar-provita.ro"
            />
          </a>

          <a
            className="anchor-footer-social"
            target="blank"
            href="https://www.instagram.com/ctfrizanesti/?igsh=M3I0ejY3cXMwc2px">
            <img
              className="image-contact-line-social"
              src={Instagram}
              alt="contact@voluntar-provita.ro"
            />
          </a>
        </div>
      </div>

      <div className="middle-footer">
        <div className="first-row">
          <p>Voluntar - Pro Vita</p>
          <p>Asociația Pro Vita pentru născuți și nenăscuți</p>
          <p>Cod fiscal: 7250977</p>
        </div>
        <div className="second-row">
          <p>IBAN RON: RO76BTRLRONCRT0P25992903</p>

          <p>IBAN EUR: RO18BTRLEURCRT0P25992901</p>
          <p>IBAN USD: RO58BTRLUSDCRT0P25992901</p>
          <p>SWIFT: BTRLRO22 Banca Transilvania - Vălenii de Munte</p>
        </div>
        <div className="third-row">
          <a
            className="anchor-location-footer"
            target="blank"
            href="https://maps.app.goo.gl/tXAeELeJ3kFY9ckq8">
            <p>Asociatia Pro Vita</p>
            <p>str. Rizănești, nr. 40</p>
            <p>Vălenii de Munte, Prahova</p>
          </a>
        </div>
      </div>
      <div className="bottom-footer">
        <p className="third-line">
          © 2024 Voluntar-ProVita Toate drepturile rezervate.
        </p>
        <div className="created">
          <p className="upvisionmedia">creat de </p>
          <a
            className="upvisionmedia-logo"
            style={{
              color: "#fc4308",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            rel="noopener noreferrer"
            target="blank"
            href="http://upvisionmedia.com">
            {" "}
            <img className="upvm" src={UPVM} alt="upv" />
          </a>
        </div>
        <p
          className="third-line"
          onClick={toggleModal}
          style={{ cursor: "pointer" }}>
          Termeni și Condiții
        </p>
      </div>

      <Termeni isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default Footer;
