// src/components/ModalScrezii.jsx
import React from "react";
import "../styling/ModalCommunity.css";

const ModalScrezii = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    if (e.target.className === "modal-overlay") {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content">
        <h2 className="title-modal-community">Tabăra din Valea Screzii</h2>

        <p>
          Suntem o comunitate de peste 250 de suflete, mame singure și copii,
          persoane defavorizate, victime ale violenței domestice și vârstnici
          abandonați. Trăim într-un peisaj de poveste, în pitorescul sat Valea
          Screzii, în casele oferite de{" "}
          <a href="https://www.asociatia-provita.org/" target="blank">
            {" "}
            Asociația Pro Vita{" "}
          </a>
          ". Casele noastre sunt mângâiate de soare și vegheate de frumoasa
          Biserică din deal.
        </p>

        <h3 className="subtitle-modal-community">Viața la Valea Screzii</h3>
        <p>
          Avem o fermă cu găini, vaci, măgari și ponei care ne ocupă timpul, iar
          cultivarea fructelor și legumelor noastre este parte din activitățile
          zilnice. Ne ajutăm unii pe alții și am învățat să ne unim în credință.
        </p>
        <p>
          Aici, avem tot ce ne trebuie pentru a trăi simplu: case mobilate,
          hrană, haine, o bibliotecă pentru studiu, o bucătărie și o sală mare
          de mese, locuri de joacă, biciclete și acces la Wi-Fi.
        </p>

        <h3 className="subtitle-modal-community">Dorințele noastre</h3>
        <p>
          La fel ca toți ceilalți oameni, avem nevoi, dorințe și vise. Ne dorim
          stabilitate, locuri de muncă, siguranță și posibilitatea de a
          călători, de a studia și de a deveni independenți.
        </p>

        <h3 className="subtitle-modal-community">Ajutorul pe care îl primim</h3>
        <p>
          Primim sprijin din partea unor oameni buni și companii generoase care
          au înțeles să acopere nevoile noastre de bază. Mulți dintre acești
          oameni ne devin prieteni pe viață și se reîntorc pentru a se bucura de
          energia locului.
        </p>

        <h3 className="subtitle-modal-community">Oamenii care ne sprijină</h3>
        <p>
          Avem o echipă dedicată care ne ajută zi de zi. Andreea, mâna de fier a
          asociației, se asigură că nu ne lipsește nimic, Mioara gestionează
          treburile administrative, iar Marian se ocupă de dosarele noastre
          sociale. Gabriel și Gheorghe se ocupă de ferma noastră, iar Mia și
          Elena ajută mamele cu activitățile casnice și de bucătărie.
        </p>
        <p>
          Lenuța și Luminița sunt expertele noastre în bucătărie, cunoscute
          pentru cele mai bune sarmale și ciorbă de fasole. Asistența medicală
          ne este asigurată de Diana, iar Alina ajută copiii cu temele. Domnul
          Neluș coordonează Ansamblul Pro Vita, format din fetele noastre
          talentate, iar Cristi se ocupă de corul copiilor Pro Vita și de
          imortalizarea momentelor frumoase în fotografii și filme.
        </p>

        <h3 className="subtitle-modal-community">Contribuția noastră</h3>
        <p>
          Contribuim și noi la bunul mers al comunității: facem curățenie,
          reparații, ajutăm în bucătărie și în agricultură, colindăm și
          sprijinim asociația în diverse proiecte. Ne primim oaspeții cu căldură
          și zâmbete și ne rugăm pentru ei, astfel încât să fie sănătoși și
          puternici pentru a ne putea ajuta în continuare.
        </p>
      </div>
    </div>
  );
};

export default ModalScrezii;
