import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Community from "./pages/Community";
import Contact from "./pages/Contact";
import Help from "./pages/Help";
import Home from "./pages/Home";
import How from "./pages/How";
import "./styling/App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <How />
      <Community />
      <Help />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
