import React from "react";
import "../styling/Termeni.css"; // Ensure this points to the correct styling file

const Termeni = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal-footer"
      onClick={(e) => e.target.className === "modal-footer" && onClose()}>
      <div className="modal-content-footer">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Termeni și condiții</h2>
        <p>
          Bun venit pe site-ul Asociației Pro Vita pentru născuți și nenăscuți!
          Vă rugăm să citiți cu atenție următorii termeni și condiții înainte de
          a utiliza acest site sau de a face donații.
        </p>
        <h3>1. Acceptarea Termenilor și Condițiilor</h3>
        <p>
          Prin accesarea și utilizarea acestui site, sunteți de acord cu
          termenii și condițiile prezentate. Dacă nu sunteți de acord cu acești
          termeni, vă rugăm să nu utilizați site-ul.
        </p>
        <h3>2. Donații</h3>
        <p>
          Principala funcție a acestui site este de a facilita donațiile către
          Asociația Pro Vita. Fondurile donate vor fi utilizate pentru a
          sprijini copiii și mamele aflate în dificultate. Ne angajăm să folosim
          resursele cu responsabilitate și transparență, iar rapoartele
          financiare sunt disponibile la cerere.
        </p>
        <h3>3. Protecția Datelor Personale</h3>
        <p>
          Asociația Pro Vita respectă confidențialitatea informațiilor personale
          ale donatorilor și ale altor utilizatori ai site-ului. Datele
          colectate vor fi utilizate doar pentru scopuri legate de activitatea
          noastră și nu vor fi partajate cu terți fără acordul prealabil al
          utilizatorului.
        </p>
        <h3>4. Politica de Rambursare</h3>
        <p>
          Donațiile făcute către Asociația Pro Vita sunt voluntare și
          nerambursabile. Dacă ați efectuat o plată din greșeală sau există o
          problemă, vă rugăm să ne contactați în termen de 7 zile de la
          efectuarea donației pentru a analiza situația.
        </p>
        <h3>5. Modificarea Termenilor și Condițiilor</h3>
        <p>
          Ne rezervăm dreptul de a modifica acești termeni și condiții oricând,
          fără notificare prealabilă. Vă rugăm să consultați periodic această
          secțiune pentru a fi la curent cu orice schimbări.
        </p>
        <h3>6. Contact</h3>
        <p>
          Pentru orice întrebări sau clarificări legate de acești termeni și
          condiții, ne puteți contacta la: <br />
          Email: contact@provita.ro <br />
          Telefon: +40 123 456 789
        </p>
      </div>
    </div>
  );
};

export default Termeni;
