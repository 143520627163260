import React from "react";
import "../styling/Help.css";
import Image1 from "../assets/bancar.webp";
import Image2 from "../assets/impozit.webp";
import Image3 from "../assets/profit.webp";
import Image4 from "../assets/sms.webp";
import Image5 from "../assets/prieten.webp";
import Image6 from "../assets/voluntar.webp";

const Help = () => {
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Textul a fost copiat! " + text);
      })
      .catch((err) => {
        alert("A apărut o problemă la copiere.");
      });
  }

  return (
    <div id="help" className="help-container">
      <h3 className="help-title">Ajută și tu!</h3>
      <div className="help-cards-container">
        <div className="card-help">
          <div className="image-holder-help">
            <img
              className="image-help-card"
              src={Image1}
              alt="doneaza prin transfer bancar"
            />
            <p className="text-on-image">DONEAZĂ PRIN TRANSFER BANCAR</p>
          </div>
          <div className="data-help">
            <p className="text-p-help">
              IBAN RON - RO76BTRLRONCRT0P25992903
              <span
                className="copy-icon"
                onClick={() => copyToClipboard("RO76BTRLRONCRT0P25992903")}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 21H9C7.89543 21 7 20.1046 7 19V7C7 5.89543 7.89543 5 9 5H15L19 9V19C19 20.1046 18.1046 21 17 21ZM13 3V8H18M21 15V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12M14 14H10M14 18H10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </p>
            <p className="text-p-help">
              IBAN EUR - RO18BTRLEURCRT0P25992901
              <span
                className="copy-icon"
                onClick={() => copyToClipboard("RO18BTRLEURCRT0P25992901")}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 21H9C7.89543 21 7 20.1046 7 19V7C7 5.89543 7.89543 5 9 5H15L19 9V19C19 20.1046 18.1046 21 17 21ZM13 3V8H18M21 15V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12M14 14H10M14 18H10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </p>
            <p className="text-p-help">
              IBAN USD - RO58BTRLUSDCRT0P25992901
              <span
                className="copy-icon"
                onClick={() => copyToClipboard("RO58BTRLUSDCRT0P25992901")}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 21H9C7.89543 21 7 20.1046 7 19V7C7 5.89543 7.89543 5 9 5H15L19 9V19C19 20.1046 18.1046 21 17 21ZM13 3V8H18M21 15V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12M14 14H10M14 18H10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </p>
            <p className="text-p-help">
              SWIFT: BTRLRO22
              <span
                className="copy-icon"
                onClick={() => copyToClipboard("BTRLRO22")}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 21H9C7.89543 21 7 20.1046 7 19V7C7 5.89543 7.89543 5 9 5H15L19 9V19C19 20.1046 18.1046 21 17 21ZM13 3V8H18M21 15V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12M14 14H10M14 18H10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </p>

            <p className="text-p-help">
              Banca Transilvania - Vălenii de Munte, Asociația Pro Vita pentru
              născuți și nenăscuți Cod fiscal: 7250977
              <span
                className="copy-icon"
                onClick={() =>
                  copyToClipboard(
                    "Banca Transilvania - Vălenii de Munte, Asociația Pro Vita pentru născuți și nenăscuți Cod fiscal: 7250977"
                  )
                }>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19 21H9C7.89543 21 7 20.1046 7 19V7C7 5.89543 7.89543 5 9 5H15L19 9V19C19 20.1046 18.1046 21 17 21ZM13 3V8H18M21 15V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12M14 14H10M14 18H10"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </p>
          </div>
        </div>
        <div className="card-help">
          <div className="image-holder-help">
            <img
              className="image-help-card"
              src={Image2}
              alt="REDIRECȚIONEAZĂ 3,5 % DIN IMPOZITUL PE VENIT."
            />
            <p className="text-on-image">
              REDIRECȚIONEAZĂ 3,5 % DIN IMPOZITUL PE VENIT.
            </p>
          </div>
          <div className="data-help">
            <p className="card2">
              <a
                className="anchor-help"
                href="https://static.anaf.ro/static/10/Anaf/formulare/230_OPANAF_15_2021.pdf"
                target="blank"
                rel="noopener noreferrer">
                Descarcă
              </a>
              , completează și semnează formularul. Adu-l personal sau trimite-l
              prin curier/poștă la adresa noastră: str. Rizănești, nr. 40,
              Vălenii de Munte, Prahova. Alternativ, depune-l personal sau
              trimite-l prin curier/poștă, cu confirmare de primire, la organul
              fiscal de care aparții. Adresa organului fiscal este disponibilă
              <a
                className="anchor-help"
                href="https://static.anaf.ro/static/10/Anaf/AsistentaContribuabili_r/telefoane_judete/Regiuni.htm"
                target="_blank"
                rel="noopener noreferrer">
                AICI
              </a>
              . Termen limită: 25 mai.
            </p>
          </div>
        </div>
        <div className="card-help">
          <div className="image-holder-help">
            <img
              className="image-help-card"
              src={Image3}
              alt="REDIRECȚIONEAZĂ 20% DIN IMPOZITUL PE PROFIT AL COMPANIEI TALE."
            />
            <p className="text-on-image">
              REDIRECȚIONEAZĂ 20% DIN IMPOZITUL PE PROFIT AL COMPANIEI TALE.
            </p>
          </div>
          <div className="data-help">
            <p className="card2">
              <a
                className="anchor-help"
                href="https://d1a2bc0b-0643-423a-b2fd-25f1fdb536b4.filesusr.com/ugd/55a85d_33d5569b36e5464dbbc01d99431170f9.docx?dn=CONTRACT%20DE%20SPONSORIZARE.docx"
                download>
                Descarcă Contractul de Sponsorizare.
              </a>{" "}
              Completează-l în dublu exemplar și semnează-l.   Adu-l personal
              sau trimite-l prin curier/poștă la adresa noastră din{" "}
              <a
                className="anchor-help"
                href="http://maps.app.goo.gl/ZYWJEPt6TvuByFd6A">
                 str. Rizănești, nr. 40, Vălenii de Munte, Prahova.
              </a>{" "}
              ​ Vom contrasemna documentul și îți vom trimite înapoi exemplarul
              tău.
            </p>
          </div>
        </div>
        <div className="card-help">
          <div className="image-holder-help">
            <img className="image-help-card" src={Image4} alt="PRIN SMS." />
            <p className="text-on-image">PRIN SMS.</p>
          </div>
          <div className="data-help">
            <p className="card2">
              Poți dona lunar 2 euro, trimițând un SMS cu textul{" "}
              <b> SPRIJIN </b> la numărul XXXX. Vei primi un mesaj de
              confirmare. Pentru a opri donația, trimite SMS cu textul{" "}
              <b style={{ color: "red" }}> START SPRIJIN </b> la numărul XXXX.
              Din acel moment, donația lunară va fi oprită. Numărul este valabil
              în rețelele Orange România, Vodafone România, Telekom și Digi
              Mobil.
            </p>
          </div>
        </div>
        <div className="card-help">
          <div className="image-holder-help">
            <img
              className="image-help-card"
              src={Image5}
              alt="SPONSORIZEAZĂ UN PRIETEN"
            />
            <p className="text-on-image">SPONSORIZEAZĂ UN PRIETEN</p>
          </div>
          <div className="data-help">
            <p className="card2">
              Costul cazării, meselor și sprijinului educațional și psihologic
              pentru un copil este de 200 euro pe lună. Poți contribui cu 125
              lei pe lună timp de un an. Plata poate fi unică sau împărțită în
              12 rate lunare. Pentru detalii, sună la{" "}
              <a className="anchor-help" href="tel:+40745068000">
                {" "}
                +40745068000{" "}
              </a>
              sau scrie-ne la{" "}
              <a
                className="anchor-help"
                href="mailto:centrulrizanesti@gmail.com">
                centrulrizanesti@gmail.com.
              </a>
            </p>
          </div>
        </div>
        <div className="card-help">
          <div className="image-holder-help">
            <img className="image-help-card" src={Image6} alt="FII VOLUNTAR" />
            <p className="text-on-image">FII VOLUNTAR</p>
          </div>
          <div className="data-help">
            <p className="card2">
              Poți dărui o parte din timpul, abilitățile și talentul tău pentru
              a-i sprijini pe copiii noștri! <br /> Pentru detalii, sună la{" "}
              <a className="anchor-help" href="tel:+40745068000">
                {" "}
                +40745068000{" "}
              </a>
              sau scrie-ne la{" "}
              <a
                className="anchor-help"
                href="mailto:centrulrizanesti@gmail.com">
                centrulrizanesti@gmail.com.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
