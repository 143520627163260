import React, { useState } from "react";
import "../styling/About.css";
import AboutImg from "../assets/about.svg";
import ModalAbout from "../components/ModalAbout";

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      id="about"
      className={`about-container ${isModalOpen ? "modal-open" : ""}`}>
      <div className="about-content">
        <div className="left-side-about">
          <img
            className="about-image"
            src={AboutImg}
            alt="parintele Nicolae Tanase"
          />
        </div>
        <div className="right-side-about">
          <h3 className="title-about">Despre noi</h3>
          <h4 className="subtitle-about">
            Asociația Pro Vita este o organizație non guvernamentală,
            non-profit, cu caracter social și creștin.
          </h4>
          <p className="text-about">
            Părintele Nicolae Tănase și preoteasa Maria Tănase sunt cei care au
            fondat asociația, în anul 1994, în satul Valea Plopului, unde
            împreună cu sătenii și alți voluntari și-au deschis inimile și
            casele pentru a primi copii abandonați. Prin donaţiile primite de la
            oameni de bine din toate colțurile lumii, părintele Nicolae Tănase a
            reuşit să cumpere teren şi să ridice Tabăra din Valea Screzii. În
            2016 a fost construită și Casa de tip familial Rizăneşti, din
            Vălenii de Munte. Părintele a creat o comunitate solidă, care a
            ajutat peste 10.000 de suflete de-a lungul timpului.
          </p>
          <button className="button-about-see-more" onClick={openModal}>
            vezi mai multe
          </button>
        </div>
      </div>
      <ModalAbout isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default About;
